import Vue from "vue";
import VueRouter from "vue-router";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import CreateApplication from  '@/pages/CreateApplication.vue';
import Landing from  '@/pages/Landing.vue';
import Applications from  '@/pages/Applications.vue';
import MyApplications from  '@/pages/MyApplications.vue';
import ApplicationDetail from  '@/pages/ApplicationDetail.vue';
import DomainClasses from  '@/pages/DomainClasses.vue';
import DomainClassDetail from  '@/pages/DomainClassDetail.vue';
import Fields from  '@/pages/Fields.vue';
import FieldDetail from  '@/pages/FieldDetail.vue';
import DetailViewTables from  '@/pages/DetailViewTables.vue';
import DetailViewTableDetail from  '@/pages/DetailViewTableDetail.vue';
import MappedClasses from  '@/pages/MappedClasses.vue';
import MappedClassDetail from  '@/pages/MappedClassDetail.vue';
import Names from  '@/pages/Names.vue';
import NameDetail from  '@/pages/NameDetail.vue';
import Submenus from  '@/pages/Submenus.vue';
import SubmenuDetail from  '@/pages/SubmenuDetail.vue';
import FieldTypes from  '@/pages/FieldTypes.vue';
import FieldTypeDetail from  '@/pages/FieldTypeDetail.vue';
import Users from  '@/pages/Users.vue';
import UserDetail from  '@/pages/UserDetail.vue';
import Roles from  '@/pages/Roles.vue';
import RoleDetail from  '@/pages/RoleDetail.vue';
import UserRoles from  '@/pages/UserRoles.vue';
import UserRoleDetail from  '@/pages/UserRoleDetail.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/HomeView.vue"),
		redirect: '/landing',
  },
  {
    path: "/sign-in",
	name: 'Sign-In',
	layout: DefaultLayout,
    component: () => import("../views/Sign-In.vue"),
    meta: { hideHeader: true },      
  },
  {
    path: "/sign-up",
	name: 'Sign-Up',
    component: () => import("../views/Sign-Up.vue"),
    meta: { hideHeader: true },      
  },
  {
    path: "/checkout",
	name: 'Checkout',
	layout: DefaultLayout,
    component: () => import("../views/Checkout.vue"),
    meta: { hideHeader: true },      
  },
  {
    path: "/checkout-cancel",
	name: 'Checkout-Cancel',
	layout: DefaultLayout,
    component: () => import("../views/Checkout-Cancel.vue"),
    meta: { hideHeader: true },      
  },
  {
    path: "/checkout-success",
	name: 'Checkout-Success',
	layout: DefaultLayout,
    component: () => import("../views/Checkout-Success.vue"),
    meta: { hideHeader: false },      
  },
  {
    path: "/pricing",
    name: "PricingView",
    component: () => import("../views/PricingView.vue"),
  },
  {
    path: "/app-gallery",
    name: "AppGalleryView",
		layout: DefaultLayout,
		component: Applications,
  },
  {
    path: "/checkout/:packageName",
    name: "CheckoutView",
    component: () => import("../views/Sign-Up.vue"),
    meta: { hideHeader: true },      
  },
  {
    path: "/stripe-checkout",
    name: "StripeCheckoutView",
    component: () => import("../views/StripeCheckoutView.vue"),
  },
	{
		path: '/landing',
		name: 'Landing',
		layout: DefaultLayout,
		component: Landing,
		meta: { hideHeader: false },
	},
	{
		path: '/create',
		name: 'CreateApplication',
		layout: DefaultLayout,
		component: CreateApplication,
		meta: { hideHeader: false },
	},
	{
		path: '/my-apps',
		name: 'MyApplications',
		layout: DefaultLayout,
		component: MyApplications,
	},
	{
	    path: '/application/:applicationId', 
	    name: 'ApplicationDetail',
		layout: DefaultLayout,
	    component: ApplicationDetail,
	    props: true // Pass route params as props to the component
  	},
	{
		path: '/domainClasses',
		name: 'DomainClasses',
		layout: DefaultLayout,
		component: DomainClasses,
	},
	{
	    path: '/domainClass/:domainClassId', 
	    name: 'DomainClassDetail',
		layout: DefaultLayout,
	    component: DomainClassDetail,
	    props: true // Pass route params as props to the component
  	},
	{
		path: '/fields',
		name: 'Fields',
		layout: DefaultLayout,
		component: Fields,
	},
	{
	    path: '/field/:fieldId', 
	    name: 'FieldDetail',
		layout: DefaultLayout,
	    component: FieldDetail,
	    props: true // Pass route params as props to the component
  	},
	{
		path: '/detailViewTables',
		name: 'DetailViewTables',
		layout: DefaultLayout,
		component: DetailViewTables,
	},
	{
	    path: '/detailViewTable/:detailViewTableId', 
	    name: 'DetailViewTableDetail',
		layout: DefaultLayout,
	    component: DetailViewTableDetail,
	    props: true // Pass route params as props to the component
  	},
	{
		path: '/mappedClasses',
		name: 'MappedClasses',
		layout: DefaultLayout,
		component: MappedClasses,
	},
	{
	    path: '/mappedClass/:mappedClassId', 
	    name: 'MappedClassDetail',
		layout: DefaultLayout,
	    component: MappedClassDetail,
	    props: true // Pass route params as props to the component
  	},
	{
		path: '/names',
		name: 'Names',
		layout: DefaultLayout,
		component: Names,
	},
	{
	    path: '/name/:nameId', 
	    name: 'NameDetail',
		layout: DefaultLayout,
	    component: NameDetail,
	    props: true // Pass route params as props to the component
  	},
	{
		path: '/submenus',
		name: 'Submenus',
		layout: DefaultLayout,
		component: Submenus,
	},
	{
	    path: '/submenu/:submenuId', 
	    name: 'SubmenuDetail',
		layout: DefaultLayout,
	    component: SubmenuDetail,
	    props: true // Pass route params as props to the component
  	},
	{
		path: '/fieldTypes',
		name: 'FieldTypes',
		layout: DefaultLayout,
		component: FieldTypes,
	},
	{
	    path: '/fieldType/:fieldTypeId', 
	    name: 'FieldTypeDetail',
		layout: DefaultLayout,
	    component: FieldTypeDetail,
	    props: true // Pass route params as props to the component
  	},
	{
		path: '/users',
		name: 'Users',
		layout: DefaultLayout,
		component: Users,
	},
	{
	    path: '/user/:userId', 
	    name: 'UserDetail',
		layout: DefaultLayout,
	    component: UserDetail,
	    props: true // Pass route params as props to the component
  	},
	{
		path: '/roles',
		name: 'Roles',
		layout: DefaultLayout,
		component: Roles,
	},
	{
	    path: '/role/:roleId', 
	    name: 'RoleDetail',
		layout: DefaultLayout,
	    component: RoleDetail,
	    props: true // Pass route params as props to the component
  	},
	{
		path: '/userRoles',
		name: 'UserRoles',
		layout: DefaultLayout,
		component: UserRoles,
	},
	{
	    path: '/userRole/:userRoleId', 
	    name: 'UserRoleDetail',
		layout: DefaultLayout,
	    component: UserRoleDetail,
	    props: true // Pass route params as props to the component
  	},
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
