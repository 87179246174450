<template>
  <div class="container">
    <div class="form-group">
      <h3 class="lets-create-text">Let's create an app in 60 seconds!</h3>

      <textarea 
        class="creation-prompt" 
        placeholder="Briefly describe your idea for a web app. 
        'an eBay clone called myEbayClone'
        'an air travel reservation platform called flyMeThere'"
        v-model="applicationToAdd.creationPrompt"
        rows="5">
      </textarea>

      <!-- Dropdown selectors for FrontEnd, BackEnd, Database (horizontal layout) -->
      <div class="form-row">
        <div class="form-group">
          <label class="form-label">FrontEnd</label>
          <select v-model="applicationToAdd.frontEnd" class="form-control" disabled>
            <option value="VueJS">VueJS</option>
            <option value="Svelte">Svelte</option>
            <option value="ReactJS">ReactJS</option>
            <option value="AngularJS">AngularJS</option>
          </select>
          <img class="integration-image" :src="`/images/vuejs.png`" />
          More options coming soon.
        </div>

        <div class="form-group">
          <label class="form-label">BackEnd</label>
          <select v-model="applicationToAdd.backEnd" class="form-control" disabled>
            <option value="Spring Boot">Spring Boot</option>
            <option value="NodeJS">NodeJS</option>
            <option value="GoLang">GoLang</option>
            <option value="Python">Python</option>
          </select>
          <img class="integration-image" :src="`/images/spring-boot.png`" />
          More options coming soon.
        </div>

        <div class="form-group">
          <label class="form-label">Database</label>
          <select v-model="applicationToAdd.database" class="form-control" disabled>
            <option value="MariaDB">MariaDB</option>
            <option value="PostGreSQL">PostGreSQL</option>
            <option value="MongoDB">MongoDB</option>
            <option value="DynamoDB">DynamoDB</option>
          </select>
          <img class="integration-image" :src="`/images/mariadb.png`" />
          More options coming soon.
        </div>
      </div>
      <button type="button" class="btn-starterup" @click="handleAddSubmitted()">Create Now</button>
    </div>

    <!-- Popup Component -->
  <div v-if="showPopup" class="popup-overlay">
    <div class="popup">
      <div v-if="isLoading" class="loading-content">
        <div class="loading-messages">

          <div class="loading-message-1">
          	<div class="message-box">We will be taking you to your <br> Application Detail View <br> in less than 60 seconds! </div>
          </div>

          <div class="loading-message" v-for="(message, index) in messages" :key="index" >
            <!-- Images and Checkmarks to the Left of the Message -->
			<div class="loading-icon">
			  <span v-if="!taskCompletion[index]" :class="spinnerClass(index)">🔄</span>
			  <span v-else class="completed-check">✅</span>
			</div>
            <!-- Message Text -->
            <div class="message-box">{{ message }}</div>
          </div>
          
          <div class="loading-message-1">
          	<div class="message-box">Your app will be up and running shortly!</div>
          </div>          
         
        </div>
      </div>
      <div v-else>
        <div>{{ resultMessage }}</div>
        <button v-if="okButtonEnabled" @click="proceedToDetails" class="btn-ok">OK</button>
      </div>
    </div>
  </div>        
  </div>
</template><script>

import UserService from "../services/UserService";
import ApplicationService from "../services/ApplicationService";

export default {
  data() {
    return {
      rememberMe: true,
      applicationToAdd: {
        creationPrompt: '',
        frontEnd: 'VueJS',  // default selection
        backEnd: 'Spring Boot',  // default selection
        database: 'MariaDB'  // default selection
      },
		showPopup: false, // Controls visibility of the popup
	    isLoading: true, // Controls loading state
	    resultMessage: '', // Message to display after the server responds
	    okButtonEnabled: true, // Enables 'OK' button once response is received
	    taskCompletion: [false, false, false, false, false, false, false],
	    messages: [
        	"Constructing your data model...",
        	"Building your database specs...",
        	"Creating your application...",
        	"Generating your code...",
        	"Sharing your code via Github...",
        	"Starting deployment...",
      	],
    };
  },
  created() {
    // Check for authToken in localStorage
    const authToken = localStorage.getItem('authToken');
    if (!authToken) {
      // Redirect to the sign-up page if the token is not found
      console.log("redirecting to SignUp");
      this.$router.push({ name: 'Sign-Up' }); // Adjust the route name as needed
    }
    else {
    	console.log("NOT redirecting to Sign-Up");
    }
  },
	methods: {
	  spinnerClass(index) {
	    return this.taskCompletion[index] ? 'completed-check' : 'loading-spinner';
	  },	  
  	  async handleAddSubmitted() {
	    // Show the popup and start the loading spinner
	    this.showPopup = true;
	    this.isLoading = true;
	    this.okButtonEnabled = true;
	    this.resultMessage = '';
	
	    const currentDate = new Date().getTime();
	    this.applicationToAdd.created = currentDate;
	
	    const jsonData = JSON.stringify(this.applicationToAdd);
        //console.log(jsonData);
	    
		// Sequentially update each task completion after 1.5 seconds
	    this.taskCompletion.forEach((_, index) => {
	      setTimeout(() => {
	        this.$set(this.taskCompletion, index, true);
	      }, (index + 1) * 5000); // Each spinner turns to check mark after 1.5 seconds
		});
    
	    try {
	      const response = await ApplicationService.addApplication(jsonData);
	      const applicationId = response.data.applicationId;
	
	      // Stop the loading spinner, show success message, enable OK button
	      // this.isLoading = false;
	      // this.resultMessage = 'Application created successfully!';
	
	      // Define navigation method with applicationId for proceeding
	      //this.proceedToDetails = () => {
	        this.$router.push({ 
	          name: 'ApplicationDetail', 
	          params: { applicationId } 
	        });
	      //};
	    } catch (error) {
	      this.isLoading = false;
	      this.resultMessage = 'An error occurred. Please try again later.';
	      this.okButtonEnabled = true;

	      // Error: hide popup on OK
	      this.proceedToDetails = () => {
	        this.showPopup = false;
	      };
      	      //console.error(error);
	    }
	  }
	}
};
</script>


<style scoped>
/* General Styles */
.container {
  max-width: 600px;
  margin: 100px auto;
  padding: 20px;
  background-color: #f7f9fc;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.create-application h3 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
}

.creation-prompt {
  width: 100%;
  padding: 15px;
  border-radius: 8px;
  border: 1px solid #ccc;
  margin-bottom: 20px;
  font-size: 16px;
  resize: none;
  box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.05);
}

.form-row {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  margin-bottom: 20px;
}

.form-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.form-label {
  font-weight: bold;
  margin-bottom: 5px;
  text-align: left;
}

.form-control {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 100%;
}

.integration-image {
  max-width: 120px;
  max-height: 100px;
  object-fit: contain;
  padding: 5px;
  margin-top: 5px;
}

.btn-starterup {
  display: inline-block;
  padding: 12px 20px;
  font-size: 18px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-starterup:hover {
  background-color: #0056b3;
}

.lets-create-text {
  font-size: 24px;
  font-weight: bold;
  padding-bottom: 7px;
}

/* Popup Styles */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 300px;
  width: 100%;
  text-align: center;
}

/* Loading Section */
.loading-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loading-messages {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.loading-message {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.loading-icon {
  width: 20px;
  text-align: center;
}

.loading-spinner {
  width: 20px;
  height: 20px;
  display: inline-block;
  animation: spin 1s linear infinite;
}

.completed-check {
  color: green;
  font-size: 18px;
}

.message-box {
  text-align: left;
  margin-left: 10px;
}

.loading-message-1 .message-box {
  text-align: center;
  margin-left: 0px;
  font-style: italic;
  font-weight: bold;
}

.message-and-icon {
  display: flex;
  align-items: center;
}

.message-text {
  margin-right: 10px;
  font-size: 16px;
}

/* Button Styles */
.btn-ok {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.btn-ok:hover {
  background-color: #0056b3;
}

/* Spinner animation */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>


