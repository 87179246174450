import axios from "axios"; 

export default axios.create({
  baseURL: "https://60secondapps.ai:8080/",  //server
//  baseURL: "http://localhost:8088/",  //local
  headers: {
    "Content-type": "application/json",
  },
});


